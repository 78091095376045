const descriptions = [
  'Is your software sufficiently usable? Does it respond correctly to different inputs? Can you use it in different environments? Will it perform its functions in an adequate time frame? Does it meet the business and technical requirements that originated its design? You need to place this critical validation and verification processes in good hands.',
  'At Nearshore Code, we conduct the necessary software testing to assure the quality of your product and provide the end user a flawless experience. Our QA staff provides integrated software testing services using multiple strategies, techniques and practices. Our experience will help you deliver successful products with minimum risk and reduced investment.',
];

const concepts = [
  {
    title: 'Manual Testing',
    content: 'Following rigorous processes during all the phases and in different scenarios to meet adequate quality standards prior to product delivery.',
    bgColor: 'bg-concept-normal-gray',
  },
  {
    title: 'Performance Testing',
    content: 'Responsiveness, stability, scalability, speed, resource usage and reliability are crucial when you talk about website and app performance.',
    bgColor: 'bg-concept-light-gray',
  },
  {
    title: 'Mobile Testing',
    content: 'Testing the app across different mobile devices to ensure a seamless experience for the user and your company to remain competitive.',
    bgColor: 'bg-concept-light-green',
  },
  {
    title: 'Test Automation',
    content: 'Sometimes manual testing is not enough to complete coverage or perform repetitive tasks. We apply testing methodologies that include in-house automation tools and frameworks.',
    bgColor: 'bg-concept-dark-gray',
  },
];

export {
  descriptions,
  concepts,
};
